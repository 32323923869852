import * as React from "react";
import { useSelector } from "react-redux";
import { ChakraProvider, Flex, Text } from "@chakra-ui/react";
import { buildTheme } from "../../services/themeBuilder";
import { RootState } from "../../store";
import "@fontsource/open-sans/400.css";
import { useConfirmationDialog } from "../../applyComponents/useConfirmationDialog";
import { getContext, saveContext } from "../../state/confirmation";
import { useConfig } from "../../hooks/useConfig";

export interface Props {
  header: React.ReactElement;
  main: React.ReactElement;
}
export const TopLevelLayout = ({ header, main }: Props): React.ReactElement => {
  const config = useSelector((state: RootState) => state.config);
  const uiConfig = useConfig();
  const theme = React.useMemo(() => {
    if (config.state !== "finished") return undefined;
    return buildTheme(config.values);
  }, [config]);

  const savedPlacements = useSelector((state: RootState) => state.saved);

  const { confirm, confirmationDialog, setHeader, setBody } =
    useConfirmationDialog({
      header: undefined,
      body: <Text alignSelf="flex-start"></Text>,
      cancelButton: { label: "", hidden: true },
      confirmButton: { label: "Done" },
      translate: true,
    });

  React.useEffect(() => {
    if (uiConfig === undefined) return;
    const singular =
      uiConfig.variableWords.school.singular.label.toLowerCase() ?? "school";
    const plural =
      uiConfig.variableWords.school.plural.label.toLowerCase() ?? "schools";
    setHeader(`Saved ${singular}!`);
    setBody(
      <Text alignSelf="flex-start">
        {`You saved your first ${singular} 🎉 Continue to save ${plural} to create a
        list.`}
      </Text>
    );
  }, [setHeader, setBody, uiConfig]);

  React.useEffect(() => {
    const confirmationDisplayed = getContext()?.confirmationDisplayed || false;
    if (savedPlacements.ids.length > 0 && !confirmationDisplayed) {
      saveContext({ confirmationDisplayed: true });
      confirm({});
    }
  }, [confirm, savedPlacements]);

  return (
    <ChakraProvider theme={theme}>
      {confirmationDialog}
      <Flex
        flexDir="column"
        height="calc(var(--vh, 1vh) * 100)"
        flexWrap="nowrap"
        letterSpacing="0.3px"
      >
        {header}
        {main}
      </Flex>
    </ChakraProvider>
  );
};
