import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

import { UIConfig } from "../types/UIConfig";
// import { config } from "../config/demo";
import { configFileName } from "../services/lang";
import { Language } from "../types/Language";
import { fetchConfigHash } from "../services/fetchConfigHash";

type ConfigSliceState =
  | { state: "loading" }
  | { state: "finished"; values: UIConfig };
const initialState: ConfigSliceState = { state: "loading" } as ConfigSliceState;

export const fetchConfigRaw = async (
  lang: Language | undefined,
  organizationPath: string
): Promise<UIConfig> => {
  // return config; // put this back when we need to run locally
  const URL = process.env.REACT_APP_BUCKET_URL;
  if (!URL || !organizationPath) return Promise.reject();
  const etag = await fetchConfigHash(organizationPath, configFileName(lang));
  const endpoint = `${URL}/output/${organizationPath}/${encodeURIComponent(
    configFileName(lang)
  )}?etag=${etag}`;

  return await axios.get(endpoint).then(({ data }) => {
    return data;
  });
};

interface FetchConfigOptions {
  lang: Language;
  organizationPath: string;
}

export const fetchConfig = createAsyncThunk(
  "config/get",
  async ({ lang, organizationPath }: FetchConfigOptions): Promise<UIConfig> => {
    return await fetchConfigRaw(lang, organizationPath);
  }
);

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setConfig(state, action: PayloadAction<UIConfig>) {
      return { state: "finished", values: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchConfig.fulfilled, (state, action) => {
      return { state: "finished", values: action.payload };
    });
  },
});

const { reducer } = configSlice;

export const { setConfig } = configSlice.actions;

export default reducer;
